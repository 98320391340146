import React from 'react'
import { Card } from 'react-bootstrap'
import Rating from './Rating'
import { Link } from 'react-router-dom'

function Product({ product }) {
    // Función para formatear el precio
    const formatPrice = (price) => {
        return price.toLocaleString('no-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')
    }

    return (
        <Card className="my-3 p-3 rounded">
            <Link to={`/product/${product._id}`}>
                <Card.Img src={product.image} />
            </Link>

            <Card.Body>
                <Link to={`/product/${product._id}`}>
                    <Card.Title as="div">
                        <strong>{product.name}</strong>
                    </Card.Title>
                </Link>

                <Card.Text as="div">
                    <div className="my-3">
                        <Rating value={product.rating} text={`${product.numReviews} anmeldelser`} color={'#f8e825'} />
                    </div>
                </Card.Text>


                <Card.Text as="h3">
                    NOK {formatPrice(product.price)}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default Product
