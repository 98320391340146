import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap'
import Rating from '../components/Rating'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listProductDetails, createProductReview } from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'

function ProductScreen({ match, history }) {
    const [qty, setQty] = useState(1)
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')
    const [showFullDescription, setShowFullDescription] = useState(false)

    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails)
    const { loading, error, product } = productDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const productReviewCreate = useSelector(state => state.productReviewCreate)
    const {
        loading: loadingProductReview,
        error: errorProductReview,
        success: successProductReview,
    } = productReviewCreate

    useEffect(() => {
        if (successProductReview) {
            setRating(0)
            setComment('')
            dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
        }

        dispatch(listProductDetails(match.params.id))

    }, [dispatch, match, successProductReview])

    const addToCartHandler = () => {
        history.push(`/cart/${match.params.id}?qty=${qty}`)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createProductReview(
            match.params.id, {
            rating,
            comment
        }
        ))
    }

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription)
    }

    const formatPrice = (price) => {
        if (price !== undefined && price !== null && !isNaN(price)) {
            return `NOK ${Number(price).toLocaleString('no-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')}`
        }
        return 'NOK N/A'
    }

    return (
        <div>
            <Link to='/' className='btn btn-light my-3'>Tilbake</Link>
            {loading ?
                <Loader />
                : error
                    ? <Message variant='danger'>{error}</Message>
                    : (
                        <div>
                            <Row>
                                <Col lg={8}>
                                    <Image src={product.image} alt={product.name} fluid />
                                    <Card className="mt-4">
                                        <Card.Body>
                                            <Card.Title as="h4">Produktbeskrivelse</Card.Title>
                                            <Card.Text className={showFullDescription ? '' : 'text-truncate'}>
                                                {product.description}
                                            </Card.Text>
                                            {product.description && product.description.length > 250 && (
                                                <Button
                                                    variant="link"
                                                    onClick={toggleDescription}
                                                    className="p-0"
                                                >
                                                    {showFullDescription ? 'Se mindre' : 'Se mer'}
                                                </Button>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg={4}>
                                    <Card>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <h3>{product.name}</h3>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Rating value={product.rating} text={`${product.numReviews} anmeldelser`} color={'#f8e825'} />
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col>Pris:</Col>
                                                    <Col>
                                                        <strong>{formatPrice(product.price)}</strong>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col>Merke:</Col>
                                                    <Col>
                                                        <strong>{product.brand}</strong>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col>Status:</Col>
                                                    <Col>
                                                        <strong>{product.countInStock > 0 ? 'På lager' : 'Utsolgt'}</strong>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>

                            {product.countInStock > 0 && (
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Antall</Col>
                                        <Col xs='auto' className='my-1'>
                                            <Form.Control
                                                as="select"
                                                value={qty}
                                                onChange={(e) => setQty(e.target.value)}
                                            >
                                                {
                                                    [...Array(product.countInStock).keys()].map((x) => (
                                                        <option key={x + 1} value={x + 1}>
                                                            {x + 1}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            )}

                            <ListGroup.Item>
                                <Button
                                    onClick={addToCartHandler}
                                    className='btn-block'
                                    disabled={product.countInStock === 0}
                                    type='button'>
                                    Legg i handlekurv
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                    <Card className="mt-3">
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <small>Pris inkluderer 25% MVA</small>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={6}>
                    <h4>Anmeldelser</h4>
                    {product.reviews.length === 0 && <Message variant='info'>Ingen anmeldelser</Message>}

                    <ListGroup variant='flush'>
                        {product.reviews.map((review) => (
                            <ListGroup.Item key={review._id}>
                                <strong>{review.name}</strong>
                                <Rating value={review.rating} color='#f8e825' />
                                <p>{review.createdAt.substring(0, 10)}</p>
                                <p>{review.comment}</p>
                            </ListGroup.Item>
                        ))}

                        <ListGroup.Item>
                            <h4>Skriv en anmeldelse</h4>

                            {loadingProductReview && <Loader />}
                            {successProductReview && <Message variant='success'>Anmeldelse sendt</Message>}
                            {errorProductReview && <Message variant='danger'>{errorProductReview}</Message>}

                            {userInfo ? (
                                <Form onSubmit={submitHandler}>
                                    <Form.Group controlId='rating'>
                                        <Form.Label>Vurdering</Form.Label>
                                        <Form.Control
                                            as='select'
                                            value={rating}
                                            onChange={(e) => setRating(e.target.value)}
                                        >
                                            <option value=''>Velg...</option>
                                            <option value='1'>1 - Dårlig</option>
                                            <option value='2'>2 - Grei</option>
                                            <option value='3'>3 - Bra</option>
                                            <option value='4'>4 - Veldig bra</option>
                                            <option value='5'>5 - Utmerket</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId='comment'>
                                        <Form.Label>Kommentar</Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            row='5'
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        ></Form.Control>
                                    </Form.Group>

                                    <Button
                                        disabled={loadingProductReview}
                                        type='submit'
                                        variant='primary'
                                    >
                                        Send
                                    </Button>

                                </Form>
                            ) : (
                                    <Message variant='info'>Vennligst <Link to='/login'>logg inn</Link> for å skrive en anmeldelse</Message>
                                )}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </div>
    )
}
        </div >
    )
}

export default ProductScreen
