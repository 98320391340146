import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form } from 'react-bootstrap'
import Product from '../components/Product'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import ProductCarousel from '../components/ProductCarousel'
import { listProducts, listCategories } from '../actions/productActions'

function HomeScreen({ history }) {
    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const { error, loading, products, page, pages } = productList

    const categoryList = useSelector(state => state.categoryList)
    const { categories } = categoryList

    const [selectedCategory, setSelectedCategory] = useState('')

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search)
        const categoryParam = urlParams.get('category') || ''
        const pageParam = urlParams.get('page') || 1
        const keywordParam = urlParams.get('keyword') || ''
        
        setSelectedCategory(categoryParam)
        dispatch(listProducts(keywordParam, pageParam, categoryParam))
        dispatch(listCategories())
    }, [dispatch, history.location.search])

    const handleCategoryChange = (e) => {
        const category = e.target.value
        setSelectedCategory(category)
        updateURL(category, 1)
    }

    const updateURL = (category, page) => {
        const searchParams = new URLSearchParams(history.location.search)
        if (category) {
            searchParams.set('category', category)
        } else {
            searchParams.delete('category')
        }
        searchParams.set('page', page)
        const keyword = searchParams.get('keyword')
        if (keyword) {
            searchParams.set('keyword', keyword)
        }
        history.push(`/?${searchParams.toString()}`)
    }

    return (
        <div>
            {!history.location.search && <ProductCarousel />}

            <h1>Siste produkter</h1>
            
            <Form.Group controlId="categorySelect" className="mb-3">
                <Form.Label>Filtrer etter kategori:</Form.Label>
                <Form.Control 
                    as="select" 
                    value={selectedCategory} 
                    onChange={handleCategoryChange}
                >
                    <option value="">Alle kategorier</option>
                    {categories && categories.map(category => (
                        <option key={category.id} value={category.slug}>
                            {category.category_name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : !products || products.length === 0 ? (
                <Message>Ingen produkter funnet i denne kategorien</Message>
            ) : (
                <div>
                    <Row>
                        {products.map(product => (
                            <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                                <Product product={product} />
                            </Col>
                        ))}
                    </Row>
                    <Paginate 
                        page={page} 
                        pages={pages} 
                        keyword={history.location.search} 
                        updateURL={updateURL}
                        selectedCategory={selectedCategory}
                    />
                </div>
            )}
        </div>
    )
}

export default HomeScreen
